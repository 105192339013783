<section class="content">
    <div class="container-fluid">
        @for (breadscrum of breadscrums; track breadscrum) {
        <div class="block-header">

            <!-- breadcrumb -->
            <app-breadcrumb [title]="breadscrum.title"
                            [items]="breadscrum.items"
                            [active_item]="breadscrum.active">
            </app-breadcrumb>
        </div>
        }


        <div class="row">
            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div class="card">
                    <div class="materialTableHeader">
                        <div class="left">
                            <ul class="header-buttons-left ms-0">
                                <li class="tbl-title">
                                    <h2>Entradas</h2>
                                </li>

                                <li class="tbl-search-box">
                                    <label for="search-input"><i class="material-icons search-icon">search</i></label>
                                    <input (keyup)="applyFilter($event)"
                                           [(ngModel)]="dataSource.filter"
                                           matInput
                                           placeholder="Buscar"
                                           type="search"
                                           #filter
                                           class="browser-default search-field"
                                           aria-label="Search box">
                                </li>

                            </ul>
                        </div>
                        <div class="right">
                            <ul class="tbl-export-btn">

                                <li class="tbl-header-btn">
                                    <div class="m-l-10"
                                         matTooltip="Nueva entrada">
                                        <button mat-mini-fab
                                                color="primary"
                                                (click)="openDialogNew()"
                                                [disabled]="loading">
                                            <mat-icon class="col-white">add</mat-icon>
                                        </button>
                                    </div>
                                </li>

                                <li class="tbl-header-btn">
                                    <div class="m-l-10"
                                         matTooltip="Recargar datos">
                                        <button mat-mini-fab
                                                (click)="getListaEntradas()"
                                                [disabled]="loading"
                                                color="primary">
                                            <mat-icon class="col-white">refresh</mat-icon>
                                        </button>
                                    </div>
                                </li>

                                <li>
                                    <div class="export-button m-l-10"
                                         matTooltip="Exportar a XLSX">
                                        <img src="assets/images/icons/xlsx.png"
                                             alt="Exportar a XLSX"
                                             (click)="exportExcel()" />
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>






                    <div class="body overflow-auto">
                        <div class="responsive_table">

                            <table mat-table
                                   [dataSource]="dataSource"
                                   matSort
                                   class="mat-elevation-z8 table table-hover">

                                <!--- Note that these columns can be defined in any order.
                        The actual rendered columns are set as a property on the row definition" -->



                                <!-- id -->
                                <ng-container matColumnDef="id">
                                    <th mat-header-cell
                                        *matHeaderCellDef
                                        mat-sort-header> Id </th>
                                    <td mat-cell
                                        *matCellDef="let rec"
                                        class="text-end">
                                        <div [innerHTML]="highlightSearch(rec.id, dataSource.filter)"></div>
                                    </td>
                                    <td mat-footer-cell
                                        *matFooterCellDef> <b>Total</b> </td>
                                </ng-container>



                                <!-- created_at -->
                                <ng-container matColumnDef="created_at">
                                    <th mat-header-cell
                                        *matHeaderCellDef
                                        mat-sort-header> Fecha </th>
                                    <td mat-cell
                                        *matCellDef="let rec">
                                        <div [innerHTML]="highlightSearch(rec.created_at, dataSource.filter)"></div>
                                    </td>
                                    <td mat-footer-cell
                                        *matFooterCellDef> </td>
                                </ng-container>







                                <!-- albaran -->
                                <ng-container matColumnDef="albaran">
                                    <th mat-header-cell
                                        *matHeaderCellDef
                                        mat-sort-header> Albarán </th>
                                    <td mat-cell
                                        *matCellDef="let rec">
                                        <div [innerHTML]="highlightSearch(rec.albaran, dataSource.filter)"></div>
                                    </td>
                                    <td mat-footer-cell
                                        *matFooterCellDef></td>
                                </ng-container>




                                <!-- proveedor -->
                                <ng-container matColumnDef="proveedor">
                                    <th mat-header-cell
                                        *matHeaderCellDef
                                        mat-sort-header> Proveedor </th>
                                    <td mat-cell
                                        *matCellDef="let rec">
                                        <div [innerHTML]="highlightSearch(rec.proveedor, dataSource.filter)"></div>
                                    </td>
                                    <td mat-footer-cell
                                        *matFooterCellDef></td>
                                </ng-container>


                                <!-- totCantidad -->
                                <ng-container matColumnDef="totCantidad">
                                    <th mat-header-cell
                                        *matHeaderCellDef
                                        mat-sort-header> Artículos </th>
                                    <td mat-cell
                                        *matCellDef="let rec"
                                        class="text-end">
                                        {{ rec.totCantidad | number: '0.0-0':'es'}}
                                    </td>

                                    <td mat-footer-cell
                                        *matFooterCellDef
                                        class="text-end"> <b>{{getTotalCantidad() | number: '0.0-0':'es' }}</b>
                                    </td>

                                </ng-container>


                                <!-- pales -->
                                <ng-container matColumnDef="pales">
                                    <th mat-header-cell
                                        *matHeaderCellDef
                                        class="text-end">
                                        Palés
                                    </th>
                                    <td mat-cell
                                        *matCellDef="let rec"
                                        class="text-end">
                                        {{ rec.pales | number: '0.0-0':'es'}}
                                    </td>
                                    <td mat-footer-cell
                                        *matFooterCellDef
                                        class="text-end">
                                        <b>{{getTotalPales() | number: '0.0-0':'es' }}</b>
                                    </td>
                                </ng-container>


                                <!-- devolucion -->
                                <ng-container matColumnDef="devolucion">
                                    <th mat-header-cell
                                        *matHeaderCellDef
                                        mat-sort-header> Devolución </th>
                                    <td mat-cell
                                        *matCellDef="let rec"
                                        class="text-center">
                                        <mat-icon *ngIf="rec.devolucion === '1'"
                                                  class="custom-check-icon">check</mat-icon>
                                    </td>
                                    <td mat-footer-cell
                                        *matFooterCellDef></td>
                                </ng-container>



                                <!-- actions -->
                                <ng-container matColumnDef="actions">
                                    <th mat-header-cell
                                        class="pr-0"
                                        *matHeaderCellDef></th>
                                    <td mat-cell
                                        *matCellDef="let row; let i=index;"
                                        class="pr-0">

                                        <span *ngIf=" row.swCompleto !== '1'">
                                            <button mat-icon-button
                                                    (click)="$event.stopPropagation()"
                                                    (click)="deleteRec(row)"
                                                    [disabled]="loading"
                                                    class="tbl-action-btn">
                                                <app-feather-icons [icon]="'trash-2'"
                                                                   [class]="'tbl-fav-delete'"></app-feather-icons>
                                            </button>
                                        </span>

                                        <span *ngIf="row.pdf_albaran">

                                            <button mat-icon-button
                                                    (click)="$event.stopPropagation()"
                                                    (click)="descargaAlbaranClick(row)"
                                                    [disabled]="loading"
                                                    class="tbl-action-btn"
                                                    matTooltip="Abrir PDF del albarán">
                                                <app-feather-icons [icon]="'file-text'"
                                                                   [class]="'tbl-fav-edit'"></app-feather-icons>
                                            </button>
                                        </span>

                                    </td>
                                    <td mat-footer-cell
                                        *matFooterCellDef></td>
                                </ng-container>




                                <!-- Mostrar las columnas Cabecera/conenido/footer -->
                                <!-- Abrir detalle del rec -->

                                <tr mat-header-row
                                    *matHeaderRowDef="displayedColumns"></tr>

                                <tr mat-row
                                    *matRowDef="let row; columns: displayedColumns;"
                                    (click)="openDialogEntradaDetall(row)"
                                    [style.cursor]="'pointer'"
                                    matRipple></tr>

                                <tr mat-footer-row
                                    *matFooterRowDef="displayedColumns"></tr>



                                <!-- Sin datos -->
                                <div *matNoDataRow
                                     class="no-results">
                                    <span *ngIf="!loading">
                                        Sin datos...
                                    </span>
                                </div>

                            </table>


                            <!-- Loading spinner -->
                            <div class="tbl-spinner-container"
                                 *ngIf="loading">
                                <mat-progress-spinner color="primary"
                                                      [diameter]="40"
                                                      mode="indeterminate"></mat-progress-spinner>
                            </div>

                            <!-- Paginator -->
                            <div class="mt-3"></div>
                            <mat-paginator #paginator
                                           [length]="dataSource.filteredData.length"
                                           [pageIndex]="0"
                                           [pageSize]="rowsLimit"
                                           [pageSizeOptions]="[5, 10, 25, 100]">
                            </mat-paginator>


                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>